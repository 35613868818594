<template>
  <div>
    <header-main></header-main>
    <div class="politics-cookies">
      <div class="politics-cookies__content-h1">
        <h1 class="politics-cookies__h1">GARANTÌAS</h1>
      </div>
      <div class="politics-cookies__content">
        <p></p>
        <b class="politics-cookies_parrafos">¿Qué son las cookies?</b>
        <p></p>
        <p class="politics-cookies_parrafos">
          Las cookies son pequeños archivos de datos que se reciben en el
          terminal desde el sitio Web visitado y se usan para registrar ciertas
          interacciones de la navegación en un sitio Web almacenando datos que
          podrán ser actualizados y recuperados. Estos archivos se almacenan en
          el ordenador del usuario y contiene datos anónimos que no son
          perjudiciales para su equipo. Se utilizan para recordar las
          preferencias del usuario, como el idioma seleccionado, datos de acceso
          o personalización de la página.
        </p>
        <p class="politics-cookies_parrafos">
          Las cookies también pueden ser utilizadas para registrar información
          anónima acerca de cómo un visitante utiliza un sitio. Por ejemplo,
          desde qué página Web ha accedido, o si ha utilizado un "banner"
          publicitario para llegar.
        </p>
        <p></p>
        <b class="politics-cookies_parrafos"
          >¿Por qué Appears utiliza cookies?</b
        >
        <p></p>
        <p class="politics-cookies_parrafos">
          Utilizamos cookies estrictamente necesarias y esenciales para que
          usted utilice nuestro sitio Web y le permitan moverse libremente,
          utilizar áreas seguras, opciones personalizadas, etc. Además,
          utilizamos cookies que recogen datos relativos al análisis de uso de
          la Web. Éstas se utilizan para ayudar a mejorar el servicio al
          usuario, midiendo el uso y el rendimiento de la página, para
          optimizarla y personalizarla.
        </p>
        <p class="politics-cookies_parrafos">
          Nuestros sitios también pueden tener enlaces de redes sociales (como
          Facebook o Instagram). Appears no controla las cookies utilizadas por
          estas Web externas. Para más información sobre las cookies de las
          redes sociales u otras Webs ajenas, aconsejamos revisar sus propias
          políticas de cookies.
        </p>

        <p></p>
        <b class="politics-cookies_parrafos">Tipos de cookies</b>
        <p></p>
        <ul>
          <li class="politics-cookies_parrafos">
            Según la entidad que las gestiona:
          </li>
        </ul>

        <p></p>
        <b class="politics-cookies_parrafos">Cookies propias</b>
        <p></p>
        <p class="politics-cookies_parrafos">
          Las cookies propias son aquellas generadas por la página que está
          visitando.
        </p>

        <p></p>
        <b class="politics-cookies_parrafos">Cookies de terceros</b>
        <p></p>
        <p class="politics-cookies_parrafos">
          Las cookies de terceros son aquellas generadas por servicios o
          proveedores externos, como Facebook, Twitter, Google, etc.
        </p>

        <ul>
          <li class="politics-cookies_parrafos">Según su finalidad:</li>
        </ul>

        <p></p>
        <b class="politics-cookies_parrafos">Cookies técnicas</b>
        <p></p>
        <p class="politics-cookies_parrafos">
          Las cookies técnicas son aquellas imprescindibles y estrictamente
          necesarias para el correcto funcionamiento de un portal Web y la
          utilización de las diferentes opciones y servicios que ofrece.
        </p>
        <p class="politics-cookies_parrafos">
          Por ejemplo, las que sirven para el mantenimiento de la sesión, la
          gestión del tiempo de respuesta, rendimiento o validación de opciones,
          utilizar elementos de seguridad, compartir contenido con redes
          sociales, etc.
        </p>

        <p></p>
        <b class="politics-cookies_parrafos">Cookies de personalización</b>
        <p></p>
        <p class="politics-cookies_parrafos">
          Estas cookies permiten al usuario especificar o personalizar algunas
          características de las opciones generales de la página Web, Por
          ejemplo, definir el idioma, configuración regional o tipo de
          navegador.
        </p>

        <p></p>
        <b class="politics-cookies_parrafos">Cookies analíticas</b>
        <p></p>
        <p class="politics-cookies_parrafos">
          Las cookies analíticas son las utilizadas por nuestros portales Web,
          para elaborar perfiles de navegación y poder conocer las preferencias
          de los usuarios del mismo con el fin de mejorar la oferta de productos
          y servicios.
        </p>
        <p class="politics-cookies_parrafos">
          Por ejemplo, mediante una cookie analítica se controlarían las áreas
          geográficas de mayor interés de un usuario, cuál es el producto de más
          aceptación, etc.
        </p>

        <p></p>
        <b class="politics-cookies_parrafos"
          >Cookies publicitarias / de publicidad</b
        >
        <p></p>
        <p class="politics-cookies_parrafos">
          Las cookies publicitarias permiten la gestión de los espacios
          publicitarios en base a criterios concretos. Por ejemplo, la
          frecuencia de acceso, el contenido editado, etc.
        </p>
        <p class="politics-cookies_parrafos">
          Las cookies de publicidad permiten a través de la gestión de la
          publicidad almacenar información del comportamiento a través de la
          observación de hábitos, estudiando los accesos y formando un perfil de
          preferencias del usuario, para ofrecerle publicidad relacionada con
          los intereses de su perfil.
        </p>

        <ul>
          <li class="politics-cookies_parrafos">Según plazo:</li>
        </ul>

        <p></p>
        <b class="politics-cookies_parrafos">Cookies de sesión</b>
        <p></p>
        <p class="politics-cookies_parrafos">
          Las cookies de sesión son aquellas que duran el tiempo que el usuario
          está navegando por la página Web y se borran al término.
        </p>

        <p></p>
        <b class="politics-cookies_parrafos">Cookies persistentes</b>
        <p></p>
        <p class="politics-cookies_parrafos">
          Estas cookies quedan almacenadas en el terminal del usuario, por un
          tiempo más largo, facilitando así el control de las preferencias
          elegidas sin tener que repetir ciertos parámetros cada vez que se
          visite el sitio Web.
        </p>

        <p></p>
        <b class="politics-cookies_parrafos"
          >Identificación de las cookies de esta página</b
        >
        <p></p>
        <p class="politics-cookies_parrafos">
          Las cookies utilizadas en esta página web pueden ser tratadas por
          nosotros y también por los terceros que se relacionan a continuación,
          con quien hemos contratado la prestación de servicios para los que se
          requiere el uso de cookies:
        </p>
        <p class="politics-cookies_parrafos">- Analytics</p>

        <p></p>
        <b class="politics-cookies_parrafos"
          >¿Cómo desactivar o eliminar cookies?</b
        >
        <p></p>
        <p class="politics-cookies_parrafos">
          Para cumplir con la legislación vigente, tenemos que pedir su permiso
          para gestionar cookies. Si decide no autorizar el tratamiento
          indicándonos su no conformidad, sólo usaríamos las cookies técnicas,
          puesto que son imprescindibles para la navegación por nuestra Web. En
          este caso, no almacenaríamos ninguna cookie. En el caso de seguir
          navegando por nuestro sitio Web sin denegar su autorización implica
          que acepta su uso.
        </p>
        <p class="politics-cookies_parrafos">
          Tenga en cuenta que, si rechaza o borra las cookies de navegación no
          podremos mantener sus preferencias, algunas características de las
          páginas no estarán operativas, no podremos ofrecerle servicios
          personalizados y cada vez que vaya a navegar por nuestra Web tendremos
          que solicitarle de nuevo su autorización para el uso de cookies.
        </p>
        <p class="politics-cookies_parrafos">
          Si, aun así, decide modificar la configuración de su acceso a la
          página Web, debe saber que es posible eliminar las cookies o impedir
          que se registre esta información en su equipo en cualquier momento
          mediante la modificación de los parámetros de configuración de su
          navegador:
        </p>
        <ul>
          <li class="politics-cookies_parrafos">
            <a
              href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              target="_blank"
              >Configuración de cookies de Internet Explorer</a
            >
          </li>
          <li class="politics-cookies_parrafos">
            <a
              href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we"
              target="_blank"
              >Configuración de cookies de Firefox</a
            >
          </li>
          <li class="politics-cookies_parrafos">
            <a
              href="https://support.google.com/chrome/answer/95647?hl=es"
              target="_blank"
              >Configuración de cookies de Google Chrome</a
            >
          </li>
          <li class="politics-cookies_parrafos">
            <a href="https://support.apple.com/es-es/HT201265" target="_blank"
              >Configuración de cookies de Safari</a
            >
          </li>
        </ul>
        <p class="politics-cookies_parrafos">
          Estos navegadores están sometidos a actualizaciones o modificaciones,
          por lo que no podemos garantizar que se ajusten completamente a la
          versión de su navegador. Para evitar estos desajustes, puede acceder
          directamente desde las opciones de su navegador que se encuentra
          generalmente en el menú de Opciones, en la sección de "Privacidad".
          (Por favor, consulte la ayuda de su navegador para más información).
        </p>

        <p></p>
        <b class="politics-cookies_parrafos"
          >¿Qué significa aceptar las cookies?</b
        >
        <p></p>
        <p class="politics-cookies_parrafos">
          Si acepta nuestras cookies, nos permite la mejora del sitio web para
          ofrecerle un acceso óptimo y darle un servicio más eficaz y
          personalizado.
        </p>
        <p class="politics-cookies_parrafos">
          Además, usted puede configurar su navegador para establecer que sólo
          los sitios Web de confianza o las páginas por las que está navegando
          en este momento puedan gestionar cookies lo que le permite seleccionar
          sus preferencias.
        </p>
        <p class="politics-cookies_parrafos">
          Proporcionando esta política, Appears demuestra el compromiso
          adquirido con la legislación vigente sobre el uso de cookies,
          proporcionándole información para que usted pueda comprender qué tipo
          de cookies utilizamos y por qué lo hacemos. Con esto, pretendemos
          proporcionarle transparencia en cuanto a los datos tratados acerca de
          la navegación realizada desde su equipo en nuestra Web.
        </p>
        <p class="politics-cookies_parrafos">
          Esta política se revisa periódicamente para asegurar su vigencia, por
          lo que puede ser modificada. Le recomendamos que visite la página con
          regularidad, donde le informaremos de cualquier actualización al
          respecto.
        </p>
      </div>
    </div>
    <footer-main></footer-main>
  </div>
</template>

<script>
import HeaderMain from "@/components/Header.vue";

import FooterMain from "@/components/Footer.vue";

export default {
  name: "CookiesPolicy",
  components: {
    HeaderMain,
    FooterMain,
  },
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: "Appears |",
      titleTemplate:
        "%s Agencia Digital | Estamos reestructurando nuestro sitio web.",
      meta: [
        {
          name: "description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "keywords",
          content:
            "Appears, paginas web, paginas web villavicencio, sitio web, seo, sem, aplicaciones a la medida, software, google ads, facebook ads, publicidad en google, publicidad en facebook, publicidad en instagram, web, website, diseño web, desarrollo web",
        },
        {
          name: "author",
          content: "Appears",
        },
        {
          name: "robots",
          content: "Index, Follow",
        },
        {
          rel: "canonical",
          href: "https://appears.com.co/",
        },
        // Tags twitter
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:site",
          content: "@appears",
        },
        {
          name: "twitter:creator",
          content: "@appears",
        },
        {
          name: "twitter:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          name: "twitter:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "twitter:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        // Tags facebook
        {
          property: "og:locale",
          content: "es_CO",
        },
        {
          property: "og:site_name",
          content: "Appears",
        },
        {
          property: "og:url",
          content: "https://appears.com.co",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          property: "og:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          property: "og:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:secure_url",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:width",
          content: "705",
        },
        {
          property: "og:image:height",
          content: "427",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.politics-cookies {
  display: grid;
  background: #f9f9f9;
  padding: 50px 7%;
}
.politics-cookies__content-h1 {
  justify-self: center;
  width: fit-content;
}
.politics-cookies__h1 {
  margin: 0;
  padding: 0 10px;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 3.75rem;
  text-align: center;
}
.politics-cookies__content {
  margin: 20px 0;
}
.politics-cookies_parrafos {
  text-align: justify;
  font-size: 17px;
  line-height: normal;
}
@media screen and (max-width: 1199px) {
  .politics-cookies {
    padding: 50px 10%;
  }
}
@media screen and (max-width: 575px) {
  .politics-cookies {
    padding: 50px 5%;
  }
}
</style>
